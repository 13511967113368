<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Report'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-row>
                  <b-col>
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="date_label"
                      label="Date :"
                      label-for="date"
                    >
                      <b-form-input
                        id="date"
                        v-model="form.date"
                        type="date"
                        size="sm"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="report_no_label"
                      label="Nomor Laporan :"
                      label-for="report_no"
                    >
                      <b-form-input
                        id="report_no"
                        type="text"
                        placeholder="Enter Text..."
                        size="sm"
                        v-model="form.no_report"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="issued_to_label"
                      label="ISSUED TO :"
                      label-for="issued_to"
                    >
                      <treeselect
                        size="sm"
                        id="issued_to"
                        v-model="form.issued_to"
                        :clearable="true"
                        :multiple="false"
                        :async="true"
                        valueFormat="label"
                        :load-options="loadOptionsIssued"
                        disabled
                      />
                    </b-form-group>

                    <h5><u>Lokasi</u></h5>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="equipment_id_label"
                      label="Equipment :"
                      label-for="equipment_id"
                    >
                      <vue-suggestion
                        id="equipment_id"
                        :items="equipment"
                        v-model="ItemEquipment"
                        :setLabel="setLabel"
                        :itemTemplate="itemTemplate"
                        @changed="inputChangeEquipment"
                        @selected="chooseEquipment"
                        inputClasses="form-control"
                        placeholder="Enter Text..."
                        size="sm"
                        disabled
                      >
                      </vue-suggestion>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="pabrik_id_label"
                      label="Pabrik :"
                      label-for="pabrik_id"
                    >
                      <b-form-input
                        id="pabrik"
                        v-model="form.pabrik"
                        type="text"
                        disabled
                        size="sm"
                      ></b-form-input>
                      <!-- <b-form-select id="pabrik_id" v-model="form.pabrik_id" :options="pabrik"></b-form-select> -->
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="plant_id_label"
                      label="Plant :"
                      label-for="plant_id"
                    >
                      <b-form-input
                        id="plant_id"
                        v-model="form.plant"
                        type="text"
                        disabled
                        size="sm"
                      ></b-form-input>
                      <!-- <b-form-select id="plant_id" v-model="form.plant_id" :options="plant"></b-form-select> -->
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <h5><u>Inspection Detail</u></h5>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="inspector_label"
                      label="Inspector :"
                      label-for="inspector"
                    >
                      <b-form-input
                        id="inspector"
                        v-model="form.inspector"
                        type="text"
                        placeholder="Enter Text..."
                        size="sm"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="co_inspector_label"
                      label="Co Inspector :"
                      label-for="co_inspector"
                    >
                      <b-form-input
                        id="co_inspector"
                        v-model="form.co_inspector"
                        type="text"
                        placeholder="Enter Text..."
                        size="sm"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <div v-if="inspection_plant.length" class="accordion" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-1 variant="info">
                            <span class="svg-icon svg-icon-sm svg-icon-white">
                              <i class="flaticon-eye text-success"></i>
                            </span>
                            Detail Inspection Plan
                          </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                          <b-card-body>
                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"
                                ><b>Mekanisme Kerusakan :</b></b-col
                              >
                              <b-col>{{ inspection_plant[0].damage_mechanism }}</b-col>
                            </b-row>

                            <b-row class="mb-2">
                              <b-col sm="6" class="text-sm-right"><b>Metode Inspeksi :</b></b-col>
                              <b-col>{{ inspection_plant[0].metode_inspeksi }}</b-col>
                            </b-row>

                            <b-row class="mb-2" v-if="inspection_plant[0].alasan_ditunda">
                              <b-col sm="6" class="text-sm-right"><b>Alasan Ditunda :</b></b-col>
                              <b-col>{{ inspection_plant[0].alasan_ditunda }}</b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>

                    <b-alert v-else show variant="warning">Belum ada Inspection Plant.</b-alert>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="file_label"
                      label="File :"
                      label-for="file"
                    >
                      <b-form-file
                        id="file"
                        accept=".docx, .doc, .ppt, .pptx, .pdf"
                        v-model="form.file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        size="sm"
                        disabled
                      ></b-form-file>
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      id="priority_label"
                      label="Prioritas :"
                      label-for="priority"
                    >
                      <b-form-select
                        size="sm"
                        id="priority"
                        v-model="form.priority"
                        :options="priority"
                        disabled
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr />

                <!-- <b-button
                  v-b-modal.modal-add-item
                  class="mb-2"
                  variant="outline-info"
                  size="sm"
                >
                  <span class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-plus"></i>
                  </span>
                  Add Item
                </b-button> -->

                <h4>Detail Item</h4>
                <b-row>
                  <b-col md="12">
                    <b-table
                      head-variant="dark"
                      bordered
                      :items="items"
                      :fields="fields"
                      :current-page="currentPage"
                      stacked="sm"
                      show-empty
                      responsive
                      @filtered="onFiltered"
                      :busy="isBusy"
                    >
                      <template #cell(no)="data">
                        {{ (currentPage - 1) * 10 + data.index + 1 }}
                      </template>

                      <template #cell(image)="data">
                        {{ data.item.img.name }}
                      </template>

                      <template #cell(actions)="data">
                        <div
                          class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1"
                          @click="data.toggleDetails"
                        >
                          <span class="svg-icon svg-icon-sm svg-icon-success">
                            <i class="flaticon-eye text-success"></i>
                          </span>
                        </div>
                      </template>

                      <template #row-details="data">
                        <b-card>
                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Critically :</b></b-col>
                            <b-col>
                              <span v-html="data.item.critically"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Status :</b></b-col>
                            <b-col>
                              <span v-html="data.item.status"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Hasil Pemeriksaan :</b></b-col>
                            <b-col>
                              <span v-html="data.item.inspection_result"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Rekomendasi :</b></b-col>
                            <b-col>
                              <span v-html="data.item.recomendation"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"
                              ><b>Perbaikan yang dilakukan :</b></b-col
                            >
                            <b-col>
                              <span v-html="data.item.repair_taken"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"
                              ><b>Rekomendasi masa mendatang :</b></b-col
                            >
                            <b-col>
                              <span v-html="data.item.future_recomendation"></span>
                            </b-col>
                          </b-row>

                          <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Method :</b></b-col>
                            <b-col>
                              <span v-html="data.item.method"></span>
                            </b-col>
                          </b-row>

                          <span
                            class="svg-icon svg-icon-sm svg-icon-dark"
                            @click="data.toggleDetails"
                          >
                            <i class="flaticon-close text-dark"></i>
                          </span>
                        </b-card>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                  <b-col md="5">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="10"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                  <b-col md="5">
                    <h4 class="float-right">
                      Showing
                      <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                      <span v-else>0</span>

                      of {{ totalRows }}
                    </h4>
                  </b-col>
                </b-row>

                <hr />

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/report/index')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark text-success"></i>
                  </span>
                  Approve
                </b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-modal
            id="modal-add-item"
            ref="modal"
            title="Form Add Item"
            size="xl"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col md="4" lg="4">
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="equipment_id_label"
                    label="Equipment :"
                    label-for="equipment_id"
                  >
                    <vue-suggestion
                      :items="equipment"
                      v-model="ItemEquipment"
                      :setLabel="setLabel"
                      :itemTemplate="itemTemplate"
                      @changed="inputChangeEquipment"
                      @selected="chooseEquipment"
                      inputClasses="form-control"
                      placeholder="Enter Text..."
                      size="sm"
                      disabled
                    >
                    </vue-suggestion>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="pabrik_id_label"
                    label="Pabrik :"
                    label-for="pabrik_id"
                  >
                    <b-form-input
                      v-model="form.pabrik"
                      type="text"
                      disabled
                      size="sm"
                    ></b-form-input>
                    <!-- <b-form-select id="pabrik_id" v-model="form.pabrik_id" :options="pabrik"></b-form-select> -->
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="plant_id_label"
                    label="Plant :"
                    label-for="plant_id"
                  >
                    <b-form-input
                      v-model="form.plant"
                      type="text"
                      disabled
                      size="sm"
                    ></b-form-input>
                    <!-- <b-form-select id="plant_id" v-model="form.plant_id" :options="plant"></b-form-select> -->
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="inspector_label"
                    label="Inspector :"
                    label-for="inspector"
                  >
                    <b-form-input
                      id="inspector"
                      v-model="form.inspector"
                      type="text"
                      placeholder="Enter Text..."
                      size="sm"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="co_inspector_label"
                    label="Co Inspector :"
                    label-for="co_inspector"
                  >
                    <b-form-input
                      id="co_inspector"
                      v-model="form.co_inspector"
                      type="text"
                      placeholder="Enter Text..."
                      size="sm"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="8" lg="8">
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="part_label"
                    label="Part :"
                    label-for="part_id"
                  >
                    <b-form-select
                      id="part_id"
                      v-model="modalForm.part_id"
                      :options="part"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="critically_label"
                    label="Critically :"
                    label-for="critically"
                  >
                    <b-form-select
                      size="sm"
                      id="critically"
                      v-model="modalForm.critically"
                      :options="critically"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="status_label"
                    label="Status :"
                    label-for="status"
                  >
                    <b-form-select
                      size="sm"
                      id="status"
                      v-model="modalForm.status"
                      :options="status"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="inspection_result_label"
                    label="Hasil Pemeriksaan :"
                    label-for="inspection_result"
                  >
                    <vue-editor
                      id="inspection_result"
                      v-model="modalForm.inspection_result"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="recomendation_label"
                    label="Rekomendasi :"
                    label-for="recomendation"
                  >
                    <vue-editor id="recomendation" v-model="modalForm.recomendation"></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="repair_taken_label"
                    label="Perbaikan yang dilakukan :"
                    label-for="repair_taken"
                  >
                    <vue-editor
                      size="sm"
                      id="repair_taken"
                      v-model="modalForm.repair_taken"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="future_recomendation_label"
                    label="Rekomendasi masa mendatang :"
                    label-for="future_recomendation"
                  >
                    <vue-editor
                      size="sm"
                      id="future_recomendation"
                      v-model="modalForm.future_recomendation"
                    ></vue-editor>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="method_label"
                    label="Metode :"
                    label-for="method"
                  >
                    <b-form-select
                      size="sm"
                      id="method"
                      v-model="modalForm.method"
                      :options="method"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    id="img_label"
                    label="Image :"
                    label-for="img"
                  >
                    <b-form-file
                      id="img"
                      accept=".jpg, .png, .jpeg, .pdf"
                      v-model="modalForm.image"
                      placeholder="Choose a photo or drop it here..."
                      drop-placeholder="Drop image here..."
                    ></b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>
            </form>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { VueEditor } from 'vue2-editor'
// import $ from 'jquery';

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import itemTemplate from '@/view/pages/item-template.vue'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 2000)
}

export default {
  mixins: [validationMixin],
  name: 'approbalreport',
  data() {
    return {
      form: {
        date: null,
        no_report: '',
        issued_to: null,
        pabrik_id: null,
        plant_id: null,
        pabrik: '',
        plant: '',
        equipment_id: null,
        inspector: null,
        co_inspector: null,
        file: null,
        priority: null,
      },
      equipment: [],
      issued: [],
      part: [],
      inspection_plant: [],
      modalForm: {
        part_id: null,
        critically: null,
        status: null,
        inspection_result: '',
        recomendation: '',
        repair_taken: '',
        future_recomendation: '',
        method: null,
        image: null,
      },
      critically: [
        { value: null, text: '-- Pilih Critically --' },
        { value: 'Low', text: 'Low' },
        { value: 'Medium', text: 'Medium' },
        { value: 'High', text: 'High' },
      ],
      priority: [
        { value: null, text: '-- Pilih Priority --' },
        { value: 'priority A', text: 'priority A' },
        { value: 'priority B', text: 'priority B' },
      ],
      status: [
        { value: null, text: '-- Pilih Status --' },
        { value: 'Sudah dilakukan', text: 'Sudah dilakukan' },
        { value: 'Belum dilakukan', text: 'Belum dilakukan' },
        {
          value: 'Dilakukan tidak sesuai rekomendasi',
          text: 'Dilakukan tidak sesuai rekomendasi',
        },
      ],
      method: [
        { value: null, text: '-- Pilih Method --' },
        { value: 'Visual', text: 'Visual' },
        { value: 'Ultrasonic Thickness', text: 'Ultrasonic Thickness' },
        { value: 'Radiography', text: 'Radiography' },
        { value: 'Ultrasonic', text: 'Ultrasonic' },
        { value: 'Liquid Penetrant', text: 'Liquid Penetrant' },
        { value: 'Magnetic Particle', text: 'Magnetic Particle' },
        { value: 'Eddy Current', text: 'Eddy Current' },
        { value: 'Leak Test', text: 'Leak Test' },
        {
          value: 'Hydrostatic Pressure Test',
          text: 'Hydrostatic Pressure Test',
        },
        { value: 'Thermography', text: 'Thermography' },
        { value: 'Hardness Test', text: 'Hardness Test' },
      ],
      items: [],
      itemsDeleted: [],
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'part', label: 'Part', sortable: true },
        { key: 'image', label: 'Images' },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 1,
      totalPerPage: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      ItemEquipment: null,
      itemTemplate,

      show: true,
      isBusy: false,
      loading: false,
      id: 0,
      urlStorage: '',
    }
  },
  validations: {
    form: {
      date: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    VueEditor,
    Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Report', route: 'alert' },
      { title: 'Edit Report' },
    ])
  },
  async created() {
    var self = this
    this.id = this.$route.params.id
    self.urlStorage = ApiService.urlStorage()

    await ApiService.get('/master/part/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var part = data.data
          for (let i = 0; i < part.length; i++) {
            self.part.push({
              value: part[i].id_part + '+' + part[i].nama_part,
              text: part[i].nama_part,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    await ApiService.get('/report/create/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.ItemEquipment = {
            id: data.data[0].id_equipment,
            name: data.data[0].nama_equipment + ' (' + data.data[0].nomor_equipment + ')',
            plant_id: data.data[0].plant_id,
            pabrik_id: data.data[0].pabrik_id,
            pabrik: data.data[0].nama_pabrik,
            plant: data.data[0].nama_plant,
          }

          self.form.date = data.data[0].date
          self.form.no_report = data.data[0].no_report

          self.issued.push({
            id: data.data[0].issued_to,
            label: data.data[0].issued_to,
          })

          self.form.issued_to = {
            id: data.data[0].issued_to,
            label: data.data[0].issued_to,
          }

          self.form.pabrik_id = data.data[0].pabrik_id
          self.form.pabrik = data.data[0].nama_pabrik
          self.form.plant = data.data[0].nama_plant
          self.form.plant_id = data.data[0].plant_id
          self.form.equipment_id = data.data[0].equipment_id
          self.form.method = data.data[0].method
          self.form.inspector = data.data[0].inspector
          self.form.co_inspector = data.data[0].co_inspector
          self.form.priority = data.data[0].priority
          self.form.repair_taken = data.data[0].repair_taken
          self.form.future_recomendation = data.data[0].future_recomendation

          var item = data.data
          for (let i = 0; i < item.length; i++) {
            self.items.push({
              id_report: item[i].id_report,
              part_id: item[i].part_id,
              part: item[i].nama_part,
              status: item[i].status,
              critically: item[i].critically,
              recomendation: item[i].recomendation,
              repair_taken: item[i].repair_taken,
              future_recomendation: item[i].future_recomendation,
              method: item[i].method,
              img: { name: item[i].image },
              status_data: 'old',
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    self.checkinspectionplant()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    toDelete(index, item) {
      // console.log(item);
      this.isBusy = true
      // Hide the modal manually
      this.$nextTick(() => {
        if (item.status == 'old') {
          this.itemsDeleted.push(item)
        }
        this.items.splice(index, 1)
        this.isBusy = false
      })
    },
    resetModal() {
      this.modalForm.part_id = null
      this.modalForm.status = null
      this.modalForm.critically = null
      this.modalForm.inspection_result = ''
      this.modalForm.recomendation = ''
      this.modalForm.repair_taken = ''
      this.modalForm.future_recomendation = ''
      this.modalForm.method = null
      this.modalForm.image = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.isBusy = true
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Push the name to submitted names
      var part = this.modalForm.part_id.split('+')

      this.items.push({
        id_report_item: 0,
        part_id: part[0],
        part: part[1],
        critically: this.modalForm.critically,
        status: this.modalForm.status,
        inspection_result: this.modalForm.inspection_result,
        recomendation: this.modalForm.recomendation,
        repair_taken: this.modalForm.repair_taken,
        future_recomendation: this.modalForm.future_recomendation,
        method: this.modalForm.method,
        img: this.modalForm.image,
        status_data: 'new',
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-item')
        this.isBusy = false
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      var self = this
      ApiService.get('/report/create/approval/' + self.id)
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.$router.push('/report/index')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      ApiService.get('/master/equipment/showequipment/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    loadOptionsIssued({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH && searchQuery.length >= 3) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/report/create/showissued/' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                var issued = data.data

                if (issued.length) {
                  self.issued.splice(0, self.issued.length)
                  for (let i = 0; i < issued.length; i++) {
                    self.issued.push({
                      id: issued[i].nik,
                      label: issued[i].nama,
                    })
                  }
                }
              }
            })
            .catch(response => {
              console.log(response)
            })

          callback(null, self.issued)
        })
      }
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
      this.form.pabrik_id = value.pabrik_id
      this.form.plant_id = value.plant_id
      this.form.pabrik = value.pabrik
      this.form.plant = value.plant

      this.checkinspectionplant()
    },
    checkinspectionplant() {
      var self = this
      if (self.form.date && self.form.equipment_id) {
        ApiService.get(
          '/inspectionplan/checkforreport/' + self.form.date + '/' + self.form.equipment_id
        )
          .then(({ data }) => {
            if (data.status == 'ok') {
              var inspection_plant = data.data
              self.inspection_plant.splice(0, self.inspection_plant.length)
              self.inspection_plant.push(inspection_plant)
            }
          })
          .catch(response => {
            console.log(response)
          })
      }
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
